
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code5 } from "./data";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayjs = require("dayjs");

export default defineComponent({
  name: "loading-state",
  data() {
    return {
      loading: true,
      currentDate: dayjs().format("YYYY-MM-DD")
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code5
    };
  }
});
